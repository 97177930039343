import _ from 'underscore';
import commonHelper, { isBlank } from 'adready-api/helpers/common';
import accountApi from 'adready-api/api/account';
import { ACCOUNT_FLAG_MNI, ACCOUNT_MNI_PREFIX_MERD } from 'adready-api/constant';
import usersApi, { userRolesApi } from 'adready-api/api/user';
import organizationApi from 'adready-api/api/organization';
import authz from 'adready-api/authz';
import { formatDateForAPI } from '@/util/apiDateFormat';
import {
  COMPARE_RANGE_PREV_DAY,
  DEMO_ADVERTISERS,
  KEY_DEMO_ADVERTISER_ID,
  MNI_MOTTO_SOLUTION_ID,
  RANGE_LAST_30_DAYS,
} from '@/constant';
import { getCampaignDateRange, isDemoInstance } from '~/util/utility-functions';
import store from '~/store';
import { dedupe } from '~/components/mixins/forklift/dedupe';
import appConsoleDataLayer from '~/api/app-console-data';

const accountLoaders = {
  /**
   * Checks if the given data is available (already loaded and in the store)
   * @param {String} key
   */
  isDataLoaded(key) {
    const data = store.get(key);
    return data && !_.isEmpty(data);
  },

  async loadAdvertiserSolutions(force, pAdvertiser) {
    const key = 'common/advertiserSolutions';
    if (!force && this.isDataLoaded(key)) {
      return Promise.resolve(store.get(key)); // nothing to do, bail out
    }

    if (!pAdvertiser) {
      return [];
    }

    return dedupe(key, () => {
      return appConsoleDataLayer
        .getSolutionsByAdvertiser(pAdvertiser.id)
        .then((data) => {
          store.set(key, data);
          return data;
        })
        .catch((e) => this.forkliftErrHandler(e, 'advertiserSolutions'));
    });
  },

  async loadAdvertiserCampaigns(force, pAccount, pAdvertiser, pSolutionId) {
    const key = 'dashboard/filters@campaignOptions';
    if (!force && this.isDataLoaded(key)) {
      return Promise.resolve(store.get(key)); // nothing to do, bail out
    }

    if (
      !pAccount ||
      !pAdvertiser ||
      isBlank(pAccount.name) ||
      isBlank(pAdvertiser.name) ||
      !pSolutionId
    ) {
      return [];
    }

    return dedupe(key, () => {
      return appConsoleDataLayer
        .fetchCampaignOptions(pAdvertiser.id, pAdvertiser.name, pAccount.name, pSolutionId)
        .then(async (campaigns) => {
          if (!campaigns.length) {
            store.set(key, []);
            store.set('dashboard/filters@adGroupOptions', []);
            return [];
          }

          // Mark recent campaigns as default checked
          const advertiserKeys = Object.keys(DEMO_ADVERTISERS).map((k) => parseInt(k, 10));
          const demoAdvertiserId = parseInt(localStorage.getItem(KEY_DEMO_ADVERTISER_ID), 10);
          const demoAdvertiserMapping = advertiserKeys.includes(demoAdvertiserId)
            ? DEMO_ADVERTISERS[demoAdvertiserId]
            : DEMO_ADVERTISERS[0];
          // Mark recent campaigns as default checked
          for (let i = 0; i < campaigns.length; i++) {
            if (isDemoInstance()) {
              if (demoAdvertiserMapping.selectedCampaign.includes(campaigns[i].value)) {
                campaigns[i].checked = true;
              }
            } else {
              campaigns[i].checked = campaigns[i].isRecent || pSolutionId === MNI_MOTTO_SOLUTION_ID;
            }
          }

          const dates = store.get('dashboard/dates');

          let dateRange;
          if (pSolutionId === MNI_MOTTO_SOLUTION_ID) {
            // const { rootState } = store;
            // const { dates1 } = rootState.dashboard;
            dateRange = {
              startDate: new Date(new Date().setDate(new Date().getDate() - 31)),
              endDate: new Date(new Date().setDate(new Date().getDate() - 2)),
              compareWith: false,
              compareStartDate: new Date(new Date().setDate(new Date().getDate() - 31)),
              compareEndDate: new Date(new Date().setDate(new Date().getDate() - 3)),
              dateRangeOption: RANGE_LAST_30_DAYS,
              dateCompareOption: COMPARE_RANGE_PREV_DAY,
            };

            store.commit('dashboard/SET_DATES', { ...dates, ...dateRange }, { root: true });
          } else if (isDemoInstance()) {
            dateRange = { ...dates };
          } else {
            dateRange = getCampaignDateRange(campaigns, true);
            store.commit('dashboard/SET_DATES', { ...dates, ...dateRange }, { root: true });
          }

          // fetch adgroups
          const campaignIds =
            campaigns
              .filter((e) => e.checked)
              ?.map((e) => e.key)
              ?.join(',') || '';
          const adGroupData = await appConsoleDataLayer.fetchAdGroupOptions(
            pAdvertiser.id,
            pSolutionId,
            campaigns
          );
          if (adGroupData?.length > 0) {
            for (let j = 0; j < adGroupData.length; j++) {
              adGroupData[j].checked = true;
              adGroupData[j].value = adGroupData[j].name;
            }
            store.set('dashboard/filters@adGroupOptions', adGroupData);
          }
          if (!dateRange.notAvailable) {
            const payload = {
              advertiser: pAdvertiser ? pAdvertiser.name : '',
              client: pAccount ? pAccount.name : '',
              campaignIds,
              adGroups: '',
              startDate: formatDateForAPI(dateRange?.startDate),
              endDate: formatDateForAPI(dateRange?.endDate),
            };
            const dataMediaType = await appConsoleDataLayer.fetchMediaTypeOptions(
              pAdvertiser.id,
              pSolutionId,
              payload
            );
            const newDataMediaType = [];
            if (dataMediaType.length !== 0) {
              for (let i = 0; i < dataMediaType.length; i++) {
                newDataMediaType.push({ value: dataMediaType[i], checked: true });
              }
              store.set('dashboard/filters@mediaTypeOptions', newDataMediaType);
            }
            const dataAudienceType = await appConsoleDataLayer.fetchAudienceOptions(
              pAdvertiser.id,
              pSolutionId,
              payload
            );
            const newDataAudienceType = [];
            if (dataAudienceType.length > 0) {
              dataAudienceType?.forEach((d) =>
                newDataAudienceType.push({ key: d.value, value: d.key, checked: false })
              );
              store.set('dashboard/filters@audienceOptions', newDataAudienceType);
            }
            const dataCreativeType = await appConsoleDataLayer.fetchCreativeOptions(
              pAdvertiser.id,
              pSolutionId,
              payload
            );
            const newDataCreativeType = [];
            if (dataCreativeType.length) {
              dataCreativeType?.forEach((d) => newDataCreativeType.push({ ...d, checked: false }));
              store.set('dashboard/filters@creativeOptions', newDataCreativeType);
            }
            const dataConversionPixel = await appConsoleDataLayer.fetchConversionPixelOptions(
              pAdvertiser.id,
              pSolutionId,
              payload
            );
            const newDataConversionPixel = [];
            if (dataConversionPixel.length) {
              dataConversionPixel?.forEach((d) =>
                newDataConversionPixel.push({ ...d, checked: true })
              );
              store.set('dashboard/filters@pixelOptions', newDataConversionPixel);
            }
          } else {
            store.set('dashboard/filters@mediaTypeOptions', []);
            store.set('dashboard/filters@audienceOptions', []);
            store.set('dashboard/filters@creativeOptions', []);
            store.set('dashboard/filters@pixelOptions', []);
          }
          store.set(key, campaigns);
          return campaigns;
        })
        .catch((e) => this.forkliftErrHandler(e, 'campaignOptions'));
    });
  },

  async loadAccount(accountId, force) {
    const key = `common/accountMap@${accountId}`;
    if (!force && this.isDataLoaded(key)) {
      return Promise.resolve(store.get(key)); // nothing to do, bail out
    }
    return dedupe(key, () => {
      return accountApi
        .account(accountId)
        .then((res) => {
          const currentUser = store.get('common/currentUser');
          if (
            currentUser &&
            authz.isAccountManager(currentUser.role.name) &&
            res.hasFlag(ACCOUNT_FLAG_MNI)
          ) {
            res.name = `${ACCOUNT_MNI_PREFIX_MERD} - ${res.name}`;
          }
          store.set(key, res);
          return res;
        })
        .catch((e) => this.forkliftErrHandler(e, 'account'));
    });
  },

  async loadUserAccounts(userId, force) {
    const key = 'common/userAccounts';
    if (!force && this.isDataLoaded(key)) {
      return Promise.resolve(store.get(key)); // nothing to do, bail out
    }
    return dedupe(key, () => {
      return accountApi
        .userAccount(userId)
        .then((res) => {
          const accounts = res.map((a) => {
            const currentUser = store.get('common/currentUser');
            if (
              currentUser &&
              authz.isAccountManager(currentUser.role.name) &&
              a.hasFlag(ACCOUNT_FLAG_MNI)
            ) {
              a.name = `${ACCOUNT_MNI_PREFIX_MERD} - ${a.name}`;
              return a;
            }
            return a;
          });
          const sortedAccounts = commonHelper.caseInsensitiveSort(accounts, 'name');
          const uniqAccounts = _.uniq(sortedAccounts, true, (a) => a.id);
          store.set(key, uniqAccounts);
          return res;
        })
        .catch((e) => this.forkliftErrHandler(e, 'userAccounts'));
    });
  },

  async loadAccounts(force) {
    const key = 'common/accounts';
    if (!force && this.isDataLoaded(key)) {
      return Promise.resolve(store.get(key)); // nothing to do, bail out
    }
    return dedupe(key, () => {
      return accountApi
        .accountAll()
        .then((res) => {
          const accounts = res.map((a) => {
            const currentUser = store.get('common/currentUser');
            if (
              currentUser &&
              authz.isAccountManager(currentUser.role.name) &&
              a.hasFlag(ACCOUNT_FLAG_MNI)
            ) {
              a.name = `${ACCOUNT_MNI_PREFIX_MERD} - ${a.name}`;
              return a;
            }
            return a;
          });
          const sortedAccounts = commonHelper.caseInsensitiveSort(accounts, 'name');
          store.set(key, sortedAccounts);
          return res;
        })
        .catch((e) => this.forkliftErrHandler(e, 'userAccounts'));
    });
  },

  async loadCurrentUser(userId, force) {
    const key = 'common/currentUser';
    if (!force && this.isDataLoaded(key)) {
      return Promise.resolve(store.get(key)); // nothing to do, bail out
    }
    return dedupe(key, () => {
      return usersApi
        .read(userId)
        .then((res) => {
          store.set(key, res);
          return res;
        })
        .catch((e) => this.forkliftErrHandler(e, 'currentUser'));
    });
  },

  async loadCurrentUserRoles(userId, force) {
    const key = 'common/currentUserRoles';
    if (!force && this.isDataLoaded(key)) {
      return Promise.resolve(store.get(key)); // nothing to do, bail out
    }
    return dedupe(key, () => {
      return userRolesApi
        .globalRoles(userId)
        .then((res) => {
          const currentUserRoles = res.data;
          if (currentUserRoles) {
            const role = currentUserRoles[0];
            store.set(key, role.name);
          }
          return res;
        })
        .catch((e) => this.forkliftErrHandler(e, 'currentUserRoles'));
    });
  },

  async loadUserAccountRoles(userId, force) {
    const key = 'common/userAccountRoles';
    if (!force && this.isDataLoaded(key)) {
      return Promise.resolve(store.get(key)); // nothing to do, bail out
    }
    return dedupe(key, () => {
      return accountApi
        .userAccountRoles(userId)
        .then((res) => {
          store.set(key, res);
          res.forEach((a) => {
            store.set(
              `common/accountRolesMap@${a.accountId}`,
              authz.createRoleMap(res, a.accountId)
            );
          });
          return res;
        })
        .catch((e) => this.forkliftErrHandler(e, 'userAccounts'));
    });
  },

  loadUsers(accountId, force) {
    const key = `common/accountUsersMap@${accountId}`;
    if (!force && this.isDataLoaded(key)) {
      return Promise.resolve(store.get(key)); // nothing to do, bail out
    }
    return dedupe(key, () => {
      return usersApi
        .index({ accountId })
        .then((users) => {
          store.set(key, users);
          return users;
        })
        .catch((e) => this.forkliftErrHandler(e, 'users'));
    });
  },

  loadAllUsers(force) {
    const key = 'common/allUsers';
    if (!force && this.isDataLoaded(key)) {
      return []; // nothing to do, bail out
    }
    return dedupe(key, () => {
      return usersApi
        .index({})
        .then((users) => {
          const sortedUser = _.sortBy(users, (u) => u.id).reverse();
          store.set(key, sortedUser);

          return sortedUser;
        })
        .catch((e) => this.forkliftErrHandler(e, 'users'));
    });
  },

  loadAllOrganizations(force) {
    const key = 'common/allOrganizations';
    if (!force && this.isDataLoaded(key)) {
      return []; // nothing to do, bail out
    }
    return dedupe(key, () => {
      return organizationApi
        .index({})
        .then((org) => {
          const sortedOrg = _.sortBy(org, (u) => u.name);
          store.set(key, sortedOrg);

          return sortedOrg;
        })
        .catch((e) => this.forkliftErrHandler(e, 'organizations'));
    });
  },

  loadAccountsData(accountId, force) {
    this.loadAccount(accountId, force);
    return true;
  },
};

export default accountLoaders;
