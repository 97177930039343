export function buildQueryString(payload) {
  if (!payload) {
    return '';
  }
  const summaryParams = new URLSearchParams();

  Object.keys(payload).forEach((key) => {
    const val = payload[key];
    if (Array.isArray(val)) {
      val.forEach((v) => {
        summaryParams.append(key, v);
      });

      return;
    }
    summaryParams.append(key, val);
  });

  return summaryParams;
}

export function getQueryParams(url) {
  const paramArr = url.slice(url.indexOf('?') + 1).split('&');
  const params = {};
  paramArr.forEach((param) => {
    const [key, val] = param.split('=');
    params[key] = decodeURIComponent(val);
  });
  return params;
}
export const generatePendoLocationUrl = ({ solution, advertiser, account, subSection }) => {
  let customUrl = `${window.location.origin}/#/`;
  if (solution) {
    customUrl += `solution=${encodeURIComponent(solution)}`;
  }
  if (subSection) {
    customUrl += `&sub-section=${encodeURIComponent(subSection)}`;
  }
  if (account) {
    customUrl += `&account=${encodeURIComponent(account)}`;
  }
  if (advertiser) {
    customUrl += `&advertiser=${encodeURIComponent(advertiser)}`;
  }
  return customUrl;
};
